var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',[(_vm.modalData.showModal)?_c('BaseModal',[_c('ResourceRolesModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"role":_vm.modalData.existingItem},on:{"close":function($event){_vm.modalData.showModal = false},"update":_vm.loadResourceRoles}})],1):_vm._e(),_c('v-card',{staticClass:"elevation-0 mt-3",attrs:{"rounded":"lg"}},[_c('v-card-title',[_c('div',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"},attrs:{"data-cy":"titleResourceRoles"}},[_vm._v(" Ressursroller ")]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),(_vm.canUpsert)?_c('BaseTooltipIconButton',{attrs:{"data-cy":"openNewResourceRole","color":"primary","btnText":"Ny rolle","icon":"mdi-plus"},on:{"handleClick":_vm.openNewResourceRole}}):_vm._e()],1),_c('v-spacer'),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.getRoles,"search":_vm.search},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"data-cy":"seeRole"},on:{"click":function($event){return _vm.displayResourceRole(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-open-in-new ")])]}}],null,true)},[_c('span',[_vm._v("Vis "+_vm._s(item.name))])]),(_vm.canUpsert)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"data-cy":"editRole"},on:{"click":function($event){return _vm.openEditResourceRole(item)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger "+_vm._s(item.place))])]):_vm._e()]}},{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"item.requiresCertification",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Ja" : "Nei")+" ")]}},{key:"item.isResource",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Ja" : "Nei")+" ")]}},{key:"item.isForVocationalSchool",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Fagskole" : "Kurs")+" ")]}},{key:"item.cannotBeCombined",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Nei" : "Ja")+" ")]}}],null,true)})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }